import {
  GET_WEEK_SLOT,
  ASSIGN_STUDENT_LIST,
  UN_ASSIGN_STUDENT_LIST,
  SET_EDIT_ASSIGN_STATE,
  OPEN_BLOCK_OFF_SLOT_MODAL_ACTION,
  RECURRING_OPEN_BLOCK_OFF_SLOT_MODAL_ACTION,
  CUSTOM_OPEN_BLOCK_OFF_SLOT_MODAL_ACTION,
  ASSIGN_STUDENT_ACTION,
  UNASSIGN_STUDENT_ACTION,
  GET_SELECTED_TEACHER_SLOT,
  CHANGE_TEACHER_ACTION,
  GET_SINGLE_WEEK_OPENBLOCKOFF,
  EDIT_OPEN_STATE,
  EDIT_BLOCKOFF_STATE,
  SET_WEEK_DATE_RANGE,
  BANK_MODAL_ACTION,
  GET_STUDENT_WEEKLY_SLOT,
  GET_DAY_SLOT,
  GET_MONTH_SLOT,
  SET_DAY_DATE_RANGE,
  SET_MONTH_DATE_RANGE,
  GET_STUDENT_RESCHEDULE_SLOT,
  GET_ROOM_LIST,
  GET_LESSON_LIST_BY_TEACHER,
  ASSIGN_CLASS_ACTION,
  UNASSIGN_CLASS_ACTION,
  GET_PREFRENCES_ROOM,
  GET_PREFRENCE_DETAILS,
  UPDATE_PREFRENCES_DETAILS,
  CHECK_IN_SCHEDULE,
  GET_PAYMENT_METHOD_LIST,
  ADD_PAYMENT_METHOD_LIST,
  DELETE_PAYMENT_METHOD_LIST,
  GET_TEACHER_DAILY_PREVIEW,
  STUDENT_BANKED_LESSON,
  RESCHEDULE_BANKED_LESSON,
  GET_ROOM_LIST_BYTIME,
  GET_CLASS_GROUP_TEACHER_ARR,
  GET_HOLIDAY_LIST
} from "../actionTypes";

import moment from "moment";

const initialState = {
  weekDateRange: {
    startDate: moment().startOf("week").format(),
    endDate: moment().endOf("week").subtract(1, "days").format(),
  },

  dayDateRange: {
    startDate: moment().startOf("day").format(),
    endDate: moment()
      .endOf("day")
      .set({ hour: 0, minute: 0, second: 0, millisecond: 0 })
      .format(),
  },

  monthDateRange: {
    startDate: moment().startOf("month").format(),
    endDate: moment().endOf("month").format(),
  },
  userId: null,
  updatePreference: {},
  slotEvent: [],
  weekSlot: [],
  daySlot: [],
  monthSlot: [],
  assignStudentList: null,
  unAssignStudentList: null,
  editStudentAssignState: {},

  editOpenState: {
    openstate: null,
    openmodal: false,
  },

  editBlockOffState: {
    blockstate: null,
    blockmodal: false,
  },

  openBlockOffSlotModal: {
    modal: false,
    tab: "open",
  },

  recurringOpenBlockOffSlotModal: {
    modal: false,
    tab: "open",
  },

  customOpenBlockOffSlotModal: {
    modal: false,
    tab: "open",
  },

  bankModalData: null,

  selectedAssignStudent: null,
  selectedUnAssignStudent: null,
  selectedAssignClass: null,
  selectedUnAssignClass: null,

  teacherList: [],
  selectedTeacherId: null,
  selectedTeacherName: null,

  singleWeekOpenBlockOffData: [],

  reScheduleSlotList: [],

  roomList: [],
  roomListByTime: [],

  lessonTypeListByTeacher: [],
  teacherDailyPreview: [],

  prefrencesRoom: [],
  paymentList: [],
  studentBankedLesson: [],
  reScheduleBankedLessonResponse: [],
  roomPage: 1,
  roomLimit: 10,
  totalPages: 0,
  totalResults: 0,

  teacherArr: [],

  holidayList: {}
};

export default function weeklySchedulingReducer(state = initialState, action) {
  // const updatePreferencesDetails = () => {
  //   let temp = { ...state };
  //   let tempForm = { ...temp.updatePreference };
  //   tempForm = action.payload;
  //   temp.updatePreference = tempForm;
  //   return temp;
  // };

  switch (action.type) {
    case "CUSTOM_SLOT":
      return {
        ...state,
        slotEvent: [
          ...state.slotEvent,
          { ...action.payload, id: state.slotEvent.length + 1 },
        ],
      };

    case GET_WEEK_SLOT:
      return {
        ...state,
        weekSlot: action.payload,
      };

    case GET_DAY_SLOT:
      return {
        ...state,
        daySlot: action.payload,
      };

    case GET_MONTH_SLOT:
      return {
        ...state,
        monthSlot: action.payload,
      };

    case GET_STUDENT_WEEKLY_SLOT:
      // console.log("student slot",action.payload);
      return {
        ...state,
        weekSlot: action.payload,
      };

    case GET_SINGLE_WEEK_OPENBLOCKOFF:
      return {
        ...state,
        singleWeekOpenBlockOffData: action.payload,
      };

    case ASSIGN_STUDENT_LIST:
      return {
        ...state,
        assignStudentList: action.payload,
      };

    case UN_ASSIGN_STUDENT_LIST:
      return {
        ...state,
        unAssignStudentList: action.payload,
      };

    case GET_SELECTED_TEACHER_SLOT:
      return {
        ...state,
        teacherList: action.payload,
        selectedTeacherId: state.selectedTeacherId ? state.selectedTeacherId : action.payload[0]?._id,
        selectedTeacherName: state.selectedTeacherName ? state.selectedTeacherName : action.payload[0]?.first_name + action.payload[0]?.last_name
      };

    case CHANGE_TEACHER_ACTION:
      return {
        ...state,
        selectedTeacherId: action.payload,
        selectedTeacherName: action.name
      };

    case SET_EDIT_ASSIGN_STATE:
      return {
        ...state,
        editStudentAssignState: action.payload,
      };

    case EDIT_OPEN_STATE:
      const { openstate, openmodal, recurringOpen } = action.payload;
      return {
        ...state,
        editOpenState: {
          ...state.editOpenState,
          openstate: openstate,
          openmodal: openmodal,
          recurringOpen: recurringOpen
        },
      };

    case EDIT_BLOCKOFF_STATE:
      const { blockstate, blockmodal } = action.payload;
      return {
        ...state,
        editBlockOffState: {
          ...state.editBlockOffState,
          blockstate: blockstate,
          blockmodal: blockmodal,
        },
      };

    case OPEN_BLOCK_OFF_SLOT_MODAL_ACTION:
      const { type, tab } = action.payload;
      return {
        ...state,
        openBlockOffSlotModal: {
          ...state.openBlockOffSlotModal,
          modal: type,
          tab: tab,
        },
      };

    case RECURRING_OPEN_BLOCK_OFF_SLOT_MODAL_ACTION:
      const { type1, tab1 } = action.payload;
      return {
        ...state,
        recurringOpenBlockOffSlotModal: {
          ...state.recurringOpenBlockOffSlotModal,
          modal: type1,
          tab: tab1,
        },
      };

    case CUSTOM_OPEN_BLOCK_OFF_SLOT_MODAL_ACTION:
      const { type2, tab2 } = action.payload;
      return {
        ...state,
        customOpenBlockOffSlotModal: {
          ...state.customOpenBlockOffSlotModal,
          modal: type2,
          tab: tab2,
        },
      };

    case BANK_MODAL_ACTION:
      return {
        ...state,
        bankModalData: action.payload,
      };

    case ASSIGN_STUDENT_ACTION:
      return {
        ...state,
        selectedAssignStudent: action.payload,
      };

    case UNASSIGN_STUDENT_ACTION:
      return {
        ...state,
        selectedUnAssignStudent: action.payload,
      };

    case ASSIGN_CLASS_ACTION:
      return {
        ...state,
        selectedAssignClass: action.payload,
      };

    case UNASSIGN_CLASS_ACTION:
      return {
        ...state,
        selectedUnAssignClass: action.payload,
      };

    case SET_WEEK_DATE_RANGE:
      const { startDate, endDate } = action.payload;
      return {
        ...state,
        weekDateRange: {
          ...state.weekDateRange,
          startDate: startDate,
          endDate: endDate,
        },
      };

    case SET_DAY_DATE_RANGE:
      return {
        ...state,
        dayDateRange: {
          ...state.dayDateRange,
          startDate: action.payload.startDate,
          endDate: action.payload.endDate,
        },
      };

    case SET_MONTH_DATE_RANGE:
      return {
        ...state,
        monthDateRange: {
          ...state.monthDateRange,
          startDate: action.payload.startDate,
          endDate: action.payload.endDate,
        },
      };

    case GET_STUDENT_RESCHEDULE_SLOT:
      return {
        ...state,
        reScheduleSlotList: action.payload,
      };

    case GET_ROOM_LIST:
      return {
        ...state,
        roomList: action.payload,
      };

      case GET_ROOM_LIST_BYTIME:
        return {
          ...state,
          roomListByTime: action.payload,
        };

    case GET_PREFRENCES_ROOM:
      return {
        ...state,
        prefrencesRoom: action.payload.results.reverse(),
        roomPage: action.payload.page,
        roomLimit: action.payload.limit,
        totalPages: action.payload.totalPages,
        totalResults: action.payload.totalResults,
      };

    case GET_LESSON_LIST_BY_TEACHER:
      // console.log("-----------",action.payload);
      return {
        ...state,
        lessonTypeListByTeacher: action.payload,
      };

    case GET_PREFRENCE_DETAILS:
      return {
        ...state,
        userId: action.payload,
      };

    case UPDATE_PREFRENCES_DETAILS:
      return {
        ...state,
        updatePreference: action.payload,
      };

    case CHECK_IN_SCHEDULE:
      return {
        ...state
      }

    case GET_PAYMENT_METHOD_LIST:
      return {
        ...state,
        paymentList: action.payload
      }

    case ADD_PAYMENT_METHOD_LIST:
      let result = [...state.paymentList, action?.payload]
      return {
        ...state,
        paymentList: result
      }

    case DELETE_PAYMENT_METHOD_LIST:
      let temp = state.paymentList.filter(item => item._id !== action?.payload._id)
      return {
        ...state,
        paymentList: temp
      }

    case GET_TEACHER_DAILY_PREVIEW:
      return {
        ...state,
        teacherDailyPreview: action.payload
      }

    case STUDENT_BANKED_LESSON:
      return {
        ...state,
        studentBankedLesson: action.payload
      }
    case RESCHEDULE_BANKED_LESSON:
      return {
        ...state,
        reScheduleBankedLessonResponse: action.payload
      }

    case GET_CLASS_GROUP_TEACHER_ARR:
      return {
        ...state,
        teacherArr : action.payload
      }

    case GET_HOLIDAY_LIST:
      return {
        ...state,
        holidayList: action.payload
      }

    default:
      return state;
  }
}

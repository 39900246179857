import { GET_SINGLE_SCHOOL_ID, GET_SINGLE_SCHOOL_ITEM, GET_ALL_SCHOOL, SHOW_MODAL, HIDE_MODAL, FAIL_TO_ADD_SCHOOL, APPLY_SORTBY_SCHOOL, SAVE_MERCHANT_INFO, GET_MERCHANT_INFO } from '../../redux/actionTypes';
import { authHeader } from '../../services/authHeader';
import SweetAlert from 'sweetalert2';
import { Loading } from '../innerLoader/action';


export const filterBySchool = (res) => dispatch => {
    dispatch({
        type: GET_ALL_SCHOOL,
        payload: res.data.data.schoolInfo,
    });
};

export const getAllScholl = (data) => async (dispatch, getState, api) => {
    const results = await api.post(`/school/privateteacher/getschoolList`, data, { headers: authHeader() })
    dispatch({
        type: GET_ALL_SCHOOL,
        payload: results.data.data
    })
    dispatch(Loading(false))
}

export const viewSchoolDetail = (id) => async (dispatch, getState, api) => {
    const results = await api.get(`/school/privateteacher/viewProfileBySchoolId/${id}`, { headers: authHeader() }).then((res) => {
        if (res.status === 200) {
            dispatch(getSingleSchollItem(res.data.data, false))
        }
        return res
    })
    return results
}

export const addSchool = (payload, role) => async (dispatch, getState, api) => {
    const data = { ...payload, role: 'admin', addrole: "School" }
    const results = await api.post(`/school/privateteacher/add`, data, { headers: authHeader() }).then(function (res) {
        return res;
    }).catch(function (err) {
        SweetAlert.fire({
            text: err?.response?.data?.message || 'Something went wrong',
            icon: "error",
          });
        return err.response;
    });
    if (results.status === 200) {
        SweetAlert.fire({ text: `${role === 'school' ? 'School Added Successfully' : 'Private Teacher Added Successfully'}`, icon: 'success' })
        dispatch(getAllScholl({
            page: 1,
            limit: 6
        }))
        dispatch(hideModal())
    } else {
        dispatch({
            type: FAIL_TO_ADD_SCHOOL,
            errMsg: results.data.message
        })
    }
}

export const updateScholl = (id, item) => async (dispatch, getState, api) => {
    const data = { ...item, status: item.status === 'active' ? true : false, userId: id }
    const results = await api.post(`/school/privateteacher/update`, data, { headers: authHeader() })
    if (results.status === 200) {
        SweetAlert.fire({ text: 'School Updated Successfully', icon: 'success' })
        dispatch(getAllScholl({
            page: 1,
            limit: 6
        }))
        dispatch(getSingleSchollItem(null, false))
        dispatch(hideModal())
    }
}

export const deleteSchool = (id) => async (dispatch, getState, api) => {
    const data = { id }
    const results = await api.delete(`/school/privateteacher/removeSchool`, { data, headers: authHeader() })
    if (results.status === 200) {
        dispatch(getAllScholl({
            page: 1,
            limit: 6
        }))
        SweetAlert.fire({ text: 'School Deleted Successfully', icon: 'success' })
    }
}

export const showModal = (modalProps) => dispatch => {
    dispatch({
        type: SHOW_MODAL,
        modalProps,
    })
}

export const hideModal = () => dispatch => {
    dispatch({
        type: HIDE_MODAL
    })
}

export const getSingleSchollItem = (item, isEdit) => dispatch => {
    dispatch({
        type: GET_SINGLE_SCHOOL_ITEM,
        payload: { item, isEdit }
    })
}

export const getSingleSchollID = (scholl_id) => dispatch => {
    dispatch({
        type: GET_SINGLE_SCHOOL_ID,
        payload: scholl_id
    })
}


export const sortBySchool = (data) => async (dispatch, getState, api) => {
    const results = await api
        .post(`/school/privateteacher/getschoolList`, data, {
            headers: authHeader(),
        })
        .then((res) => {
            if (res.status === 200) {
                const { results, totalResults, page, limit, activeSchoolCount, inActiveSchoolCount } = res.data.data
                dispatch({
                    type: APPLY_SORTBY_SCHOOL,
                    payload: { results, totalResults, page, limit, sortBy: data.sortBy, orderBy: data.orderBy, activeSchoolCount, inActiveSchoolCount },
                });
                dispatch(Loading(false));
            }
            return res;
        });
    return results;
};

export const saveMerchantInfo = (data) => async (dispatch, getState, api) => {
    const results = await api
        .post(`/merchant/save`, data, { headers: authHeader() })
        .then((res) => {
            if (res.status === 200) {
                dispatch({
                    type: SAVE_MERCHANT_INFO,
                    payload: res?.data?.data,
                });
                SweetAlert.fire({ text: 'Merchant information save successfully.', icon: 'success' })
            }
            return res;
        })
        .catch((err) => {
            return err.response;
        });
    return results;
};

export const getMerchantInfo = (data) => async (dispatch, getState, api) => {
    const results = await api
        .post(`/merchant/get`, data, { headers: authHeader() })
        .then((res) => {
            if (res.status === 200) {
                dispatch({
                    type: GET_MERCHANT_INFO,
                    payload: res?.data?.data,
                });
                // SweetAlert.fire({ text: 'Merchant information save successfully.', icon: 'success' })
            }
            return res;
        })
        .catch((err) => {
            return err.response;
        });
    return results;
};


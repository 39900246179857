import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';
import { getPlanById } from '../redux/studentBilling/action'
import { useForm } from "react-hook-form";
import { formatPhoneNumber } from "../utils";
import Validation from '../component/formValidation';
import { addSchool } from '../redux/school/action';
import SweetAlert from "sweetalert2";
import { Link, useHistory } from "react-router-dom";
import { FAIL_TO_ADD_SCHOOL } from '../redux/actionTypes';

function SignUp() {

  let { id } = useParams();
  let history = useHistory();
  const { register, handleSubmit, errors, watch, setError, getValues } = useForm({ mode: "all" });
  const dispatch = useDispatch()
  const selectedPlan = useSelector((state) => state.studentBillingState.selectedPlan)
  const totalSchool = useSelector(state => state.schoolState)

  useEffect(() => {
    dispatch(getPlanById(id))
  }, [])

  const handleRegisterForm = (state) => {
    if (selectedPlan?.price !== 0) {
      if (selectedPlan?.schoolPrivateTeacher === "school") {
        state.addrole = "School"
      } else if (selectedPlan?.schoolPrivateTeacher === "privateTeacher") {
        state.addrole = "Private teacher"
      }
    }
    state.planId = id;
    state.isSelfRegister = true;
    dispatch(addSchool(state, 'school')).then((res) => {
      if (res?.status === 200) {
        SweetAlert.fire({
          text: "school register successfully",
          icon: "success",
        });
        history.push("/login");
      } else {
        console.log(res);
      }
    })
  }

  const nameValidate = (value) => {
    const specialChar = /[`!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?~]/;
    if (specialChar.test(value)) {
      return "Special characters are not allowed"
    }
    else if (/^([0-9])/.test(value)) {
      return "first character should be alphabet"
    }
    else if (/^\s/.test(value)) {
      return "as first character whiteSpace not allowed"
    }
    return true
  }

  const numberValidate = (value) => {
    if (!value.length) {
      return true
    } else {
      if (value.length <= 9) {
        return "Minimum  10 digits are allowed"
      }
    }
  }

  return (
    <div className="fullWidth">
      <style>
        {
          "\
                  // .modal.show{pointer-events:none;background-color:transparent;}\
                  .modal-backdrop, .modal-content{background-color:transparent;}\
                  .login_details{background: #fff; border-radius: 1rem;}\
              "
        }
      </style>
      <div className="login_page signup_page">
        <div className="container">

          <div class="fullWidth mt-5">
            <h2>Owner Info</h2>
          </div>
          <div class="form-row mx-0">
            <div class="col-md-6 mb-4">
              <div className="form-group">
                <label>First Name</label>
                <div className="fullWidth position-relative">
                  <input
                    type="text"
                    className={`form-control ${!errors.first_name?.message
                      ? watch("first_name")
                        ? "valid"
                        : ""
                      : errors.first_name?.message
                        ? "invalid"
                        : ""
                      }`}
                    name="first_name"
                    autoComplete="first_name"
                    ref={register({
                      validate: nameValidate,
                      maxLength: {
                        value: 100,
                        message: 'First Name should allow only 100 characters.'
                      }
                    })}
                  />
                  <Validation errors={errors.first_name} message={errors?.first_name?.message} watch={watch("first_name")} />
                </div>
              </div>
            </div>
            <div class="col-md-6 mb-4">
              <div className="form-group">
                <label>Last Name</label>
                <div className="fullWidth position-relative">
                  <input
                    type="text"
                    className={`form-control ${!errors.last_name?.message
                      ? watch("last_name")
                        ? "valid"
                        : ""
                      : errors.last_name?.message
                        ? "invalid"
                        : ""
                      }`}
                    name="last_name"
                    autoComplete="last_name"
                    ref={register({
                      validate: nameValidate,
                      maxLength: {
                        value: 100,
                        message: 'Last Name should allow only 100 characters.'
                      }
                    })}
                  />
                  <Validation errors={errors.last_name} message={errors?.last_name?.message} watch={watch("last_name")} />
                </div>
              </div>
            </div>
            <div class="col-md-6 mb-4">
              <div className="form-group">
                <label>Email Address</label>
                <div className="fullWidth position-relative">
                  <input
                    type="email"
                    className={`form-control ${!errors.contact_person_email?.message
                      ? watch("contact_person_email")
                        ? "valid"
                        : ""
                      : errors.contact_person_email?.message
                        ? "invalid"
                        : ""
                      }`}
                    name="contact_person_email"
                    autoComplete="contact_person_email"
                    ref={
                      register({
                        pattern: {
                          value: /^[^@ ]+@[^@ ]+\.[^@ .]{2,}$/,
                          message:
                            "Please provide a valid contact person email address"
                        }
                      })
                    }
                  />
                  <Validation errors={errors.contact_person_email} message={errors?.contact_person_email?.message} watch={watch("contact_person_email")} />
                </div>
              </div>
            </div>
            <div class="col-md-6 mb-4">
              <div className="form-group">
                <label>Phone Number</label>
                <div className="fullWidth position-relative">
                  <input
                    type="tel"
                    inputMode="numeric"
                    maxLength={13}
                    className={`form-control ${!errors.phone_number?.message
                      ? watch("phone_number")
                        ? "valid"
                        : ""
                      : errors.phone_number?.message
                        ? "invalid"
                        : ""
                      }`}
                    name="phone_number"
                    autoComplete="phone_number"
                    onChange={(e) => {
                      const { value } = e.target
                      e.target.value = formatPhoneNumber(value)
                    }}
                    ref={register({
                      pattern: {
                        value: /^[0-9 !-@#$%^&*)(]{1,14}$/,
                        message:
                          "Should allow only Numeric Value"
                      },
                      validate: numberValidate
                    })}
                  />
                  <Validation errors={errors.phone_number} message={errors?.phone_number?.message} watch={watch("phone_number")} />
                </div>
              </div>
            </div>
          </div>

          <div class="fullWidth mt-5">
            <h2>School Info</h2>
          </div>
          {/* <hr class="fullWidth mb-2"></hr> */}
          <form onSubmit={handleSubmit(handleRegisterForm)}>
            <div class="form-row mx-0">
              <div class="col-md-6 mb-4">
                <div class="form-group">
                  <label>
                    School Name <span class="validation_compulsory">*</span>
                  </label>
                  <div class="fullWidth position-relative">
                    <input
                      type="text"
                      className={`form-control ${!errors.school_name?.message
                        ? watch("school_name")
                          ? "valid"
                          : ""
                        : errors.school_name?.message
                          ? "invalid"
                          : ""
                        }`}
                      name="school_name"
                      autocomplete="school_name"
                      ref={register({ required: "School Name is Required" })}
                    />
                    <Validation errors={errors.school_name} message={errors?.school_name?.message} watch={watch("school_name")} />
                  </div>
                </div>
              </div>
              <div class="col-md-6 mb-4">
                <div class="form-group">
                  <label>
                    Username <span class="validation_compulsory">*</span>
                  </label>
                  <div class="fullWidth position-relative">
                    <input
                      type="text"
                      className={`form-control ${!errors.username?.message
                        ? watch("username")
                          ? "valid"
                          : ""
                        : errors.username?.message
                          ? "invalid"
                          : ""
                        }`}
                      name="username"
                      ref={register({ required: "Username is Required" })}
                    />
                    <Validation errors={errors.username} message={errors?.username?.message} watch={watch("username")} />
                  </div>
                </div>
              </div>
              <div class="col-md-6 mb-4">
                <div className="form-group">
                  <label>Password <span className="validation_compulsory">*</span></label>
                  <div className="fullWidth position-relative">
                    <input
                      type="password"
                      className={`form-control ${!errors.password?.message
                        ? watch("password")
                          ? "valid"
                          : ""
                        : errors.password?.message
                          ? "invalid"
                          : ""
                        }`}
                      name="password"
                      ref={
                        register({
                          required: 'Password is Required',
                          minLength: {
                            value: 8,
                            message: 'Password should be at-least 8 characters.'
                          },
                          // maxLength: {
                          //     value: 50,
                          //     message: 'Password should allow only 50 characters.'
                          // },
                          pattern: {
                            value: /(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?!.*\s)(?=.*[&-_!@#$*])/,
                            message:
                              "Your password isn’t strong enough. Please try making it Stronger"
                          }
                        })
                      }
                      // defaultValue={single_school_item?.password}
                      autoComplete="new-password"
                    />
                    <Validation errors={errors.password} message={errors?.password?.message} watch={watch("password")} />
                  </div>
                </div>
              </div>
              <div class="col-md-6 mb-4">
                <div className="form-group">
                  <label>Confirm Password <span className="validation_compulsory">*</span></label>
                  <div className="fullWidth position-relative">
                    <input
                      type="password"
                      className={`form-control ${!errors.confirm_password?.message
                        ? watch("confirm_password")
                          ? "valid"
                          : ""
                        : errors.confirm_password?.message
                          ? "invalid"
                          : ""
                        }`}
                      name="confirm_password"
                      ref={
                        register({
                          required: 'Confirm password is Required',
                          validate: {
                            matchesPreviousPassword: value => {
                              const { password } = getValues();
                              return password === value || "Passwords should match!";
                            }
                          }
                        })
                      }
                    // defaultValue={single_school_item?.confirm_password}
                    />
                    <Validation errors={errors.confirm_password} message={errors?.confirm_password?.message} watch={watch("confirm_password")} />
                  </div>
                </div>
              </div>
              <div class="col-md-6 mb-4">
                <div class="form-group">
                  <label>
                    Email <span class="validation_compulsory">*</span>
                  </label>
                  <div class="fullWidth position-relative">
                    <input
                      type="email"
                      class="form-control "
                      name="email"
                      autocomplete="email"
                      className={`form-control ${!errors.email?.message
                        ? watch("email")
                          ? "valid"
                          : ""
                        : errors.email?.message
                          ? "invalid"
                          : ""
                        }`}
                      ref={register({ required: "email is Required" })}
                    />
                    <Validation errors={errors.email} message={errors?.email?.message} watch={watch("email")} />
                  </div>
                </div>
              </div>
              <div class="col-md-6 mb-4">
                <div class="form-group">
                  <label>Work Phone</label>
                  <div class="fullWidth position-relative">
                    <input
                      type="tel"
                      inputmode="numeric"
                      maxlength="13"
                      class="form-control "
                      name="working_phone"
                      autocomplete="working_phone"
                      className={`form-control ${!errors.working_phone?.message
                        ? watch("working_phone")
                          ? "valid"
                          : ""
                        : errors.working_phone?.message
                          ? "invalid"
                          : ""
                        }`}
                      ref={register({ required: "working_phone is Required" })}
                    />
                    <Validation errors={errors.working_phone} message={errors?.working_phone?.message} watch={watch("working_phone")} />
                  </div>
                </div>
              </div>
              <div class="col-md-6 mb-4">
                <div className="form-group">
                  <label>Cell Phone <span className="validation_compulsory">*</span></label>
                  <input
                    type="tel"
                    inputMode="numeric"
                    className={`form-control ${!errors.cellPhone?.message
                      ? watch("cellPhone")
                        ? "valid"
                        : ""
                      : errors.cellPhone?.message
                        ? "invalid"
                        : ""
                      }`}
                    name="cellPhone"
                    maxLength={13}
                    onChange={(e) => {
                      const { value } = e.target;
                      e.target.value = formatPhoneNumber(value);
                    }}
                    ref={register}
                  // defaultValue={isEdit ? editSellerInformation?.cellPhone : ''}
                  />
                  <Validation errors={errors.cellPhone} message={errors?.cellPhone?.message} watch={watch("cellPhone")} />
                </div>
              </div>
              <div class="col-md-6 mb-4">
                <div class="form-group">
                  <label>
                    Address 1 <span class="validation_compulsory">*</span>
                  </label>
                  <div class="fullWidth position-relative">
                    <input
                      type="textarea"
                      class="form-control "
                      name="address1"
                      autocomplete="address1"
                      maxlength="100"
                      className={`form-control ${!errors.address1?.message
                        ? watch("address1")
                          ? "valid"
                          : ""
                        : errors.address1?.message
                          ? "invalid"
                          : ""
                        }`}
                      ref={register({ required: "address1 is Required" })}
                    />
                    <Validation errors={errors.address1} message={errors?.address1?.message} watch={watch("address1")} />
                  </div>
                </div>
              </div>
              <div class="col-md-6 mb-4">
                <div class="form-group">
                  <label>Address 2</label>
                  <div class="fullWidth position-relative">
                    <input
                      type="textarea"
                      class="form-control "
                      name="address2"
                      autocomplete="address2"
                      className={`form-control`}
                      ref={register}
                    />
                    {/* <Validation errors={errors.address2} message={errors?.address2?.message} watch={watch("address2")} /> */}
                  </div>
                </div>
              </div>
              <div class="col-md-6 mb-4">
                <div class="form-group">
                  <label>
                    City <span class="validation_compulsory">*</span>
                  </label>
                  <div class="fullWidth position-relative">
                    <input
                      type="text"
                      class="form-control "
                      name="city"
                      autocomplete="city"
                      className={`form-control ${!errors.city?.message
                        ? watch("city")
                          ? "valid"
                          : ""
                        : errors.city?.message
                          ? "invalid"
                          : ""
                        }`}
                      ref={register({ required: "city is Required" })}
                    />
                    <Validation errors={errors.city} message={errors?.city?.message} watch={watch("city")} />
                  </div>
                </div>
              </div>
              <div class="col-md-6 mb-4">
                <div class="form-group">
                  <label>
                    State <span class="validation_compulsory">*</span>
                  </label>
                  <div class="fullWidth position-relative">
                    <input
                      type="text"
                      class="form-control "
                      name="state"
                      autocomplete="state"
                      className={`form-control ${!errors.state?.message
                        ? watch("state")
                          ? "valid"
                          : ""
                        : errors.state?.message
                          ? "invalid"
                          : ""
                        }`}
                      ref={register({ required: "state is Required" })}
                    />
                    <Validation errors={errors.state} message={errors?.state?.message} watch={watch("state")} />
                  </div>
                </div>
              </div>
              <div class="col-md-6 mb-4">
                <div class="form-group">
                  <label>
                    Postal Code <span class="validation_compulsory">*</span>
                  </label>
                  <div class="fullWidth position-relative">
                    <input
                      type="tel"
                      inputmode="numeric"
                      maxlength="5"
                      class="form-control "
                      name="postal_code"
                      autocomplete="postal_code"
                      className={`form-control ${!errors.postal_code?.message
                        ? watch("postal_code")
                          ? "valid"
                          : ""
                        : errors.postal_code?.message
                          ? "invalid"
                          : ""
                        }`}
                      ref={register({ required: "postal_code is Required" })}
                    />
                    <Validation errors={errors.postal_code} message={errors?.postal_code?.message} watch={watch("postal_code")} />
                  </div>
                </div>
              </div>
              <div class="col-md-6 mb-4">
                <div class="form-group">
                  <label>
                    Country <span class="validation_compulsory">*</span>
                  </label>
                  <div class="fullWidth position-relative">
                    <input
                      type="text"
                      class="form-control "
                      name="country"
                      autocomplete="country"
                      className={`form-control ${!errors.country?.message
                        ? watch("country")
                          ? "valid"
                          : ""
                        : errors.country?.message
                          ? "invalid"
                          : ""
                        }`}
                      ref={register({ required: "country is Required" })}
                    />
                    <Validation errors={errors.country} message={errors?.country?.message} watch={watch("country")} />
                  </div>
                </div>
              </div>
              {selectedPlan?.price === 0 && (<div class="col-md-6 mb-4">
                <div class="form-group">
                  <label>
                    School Role <span class="validation_compulsory">*</span>
                  </label>
                  <div class="fullWidth position-relative">
                    <select
                      className={`form-control text_ellipsis studentSelect ${!errors.addrole?.message
                        ? watch("addrole")
                          ? "valid"
                          : ""
                        : errors.addrole?.message
                          ? "invalid"
                          : ""
                        }`}
                      name="addrole"
                      ref={register({ required: "school role is Required" })}
                    >
                      <option value="">Select School Role</option>
                      <option value="Private teacher">Private Teacher</option>
                      <option value="School">School</option>
                    </select>
                    <Validation errors={errors.addrole} message={errors?.addrole?.message} watch={watch("addrole")} />
                  </div>
                </div>
              </div>)}
            </div>
            <div class="fullWidth mt-5">
              <h2>Payment Method</h2>
            </div>
            <div class="form-row mx-0">
              {/* <div class="col-md-6 mb-4">
              <div class="form-group">
                <label>
                Cardholder’s Full name<span class="validation_compulsory">*</span>
                </label>
                <div class="fullWidth position-relative">
                  <input
                    type="text"
                    class="form-control "
                    name="school_name"
                    autocomplete="school_name"
                    value=""
                  />
                </div>
              </div>
            </div> */}
              <div class="col-md-6 mb-4">
                <div class="form-group">
                  <label>
                    Plan Name
                  </label>
                  <div class="fullWidth position-relative">
                    <input
                      type="text"
                      className={`form-control ${!errors.planName?.message
                        ? watch("planName")
                          ? "valid"
                          : ""
                        : errors.planName?.message
                          ? "invalid"
                          : ""
                        }`}
                      name="planName"
                      value={selectedPlan?.planName}
                      disabled
                    />
                  </div>
                </div>
              </div>
              <div class="col-md-6 mb-4">
                <div class="form-group">
                  <label>
                    Plan Price
                  </label>
                  <div class="fullWidth position-relative">
                    <input
                      type="text"
                      className={`form-control ${!errors.planPrice?.message
                        ? watch("planPrice")
                          ? "valid"
                          : ""
                        : errors.planPrice?.message
                          ? "invalid"
                          : ""
                        }`}
                      name="planPrice"
                      value={selectedPlan?.price}
                      disabled
                    />
                  </div>
                </div>
              </div>
              <div class="col-md-6 mb-4">
                <div class="form-group">
                  <label>
                    Credit Card Number<span class="validation_compulsory">*</span>
                  </label>
                  <div class="fullWidth position-relative">
                    <input
                      type="text"
                      className={`form-control ${!errors.cardNo?.message
                        ? watch("cardNo")
                          ? "valid"
                          : ""
                        : errors.cardNo?.message
                          ? "invalid"
                          : ""
                        }`}
                      name="cardNo"
                      ref={register({ required: "cardNo is Required" })}
                    />
                    <Validation errors={errors.cardNo} message={errors?.cardNo?.message} watch={watch("cardNo")} />
                  </div>
                </div>
              </div>
              <div class="col-md-6 mb-4">
                <div class="form-group">
                  <label>
                    Expiration Date<span class="validation_compulsory">*</span>
                  </label>
                  <div class="fullWidth position-relative">
                    {/* <input type="month" id="start" name="start" min="2018-03" value="2018-05" /> */}
                    <input
                      type="month"
                      placeholder="MM/YYYY"
                      className={`form-control ${!errors.expDate?.message
                        ? watch("expDate")
                          ? "valid"
                          : ""
                        : errors.expDate?.message
                          ? "invalid"
                          : ""
                        }`}
                      name='expDate'
                      ref={register({ required: "expDate is Required" })}
                    />
                    <Validation errors={errors.expDate} message={errors?.expDate?.message} watch={watch("expDate")} />
                  </div>
                </div>
              </div>
              <div class="col-md-6 mb-4">
                <div class="form-group">
                  <label>
                    Security Code <span class="validation_compulsory">*</span>
                  </label>
                  <div class="fullWidth position-relative">
                    <input
                      type="text"
                      className={`form-control ${!errors.cardCode?.message
                        ? watch("cardCode")
                          ? "valid"
                          : ""
                        : errors.cardCode?.message
                          ? "invalid"
                          : ""
                        }`}
                      name="cardCode"
                      ref={register({ required: "Card Code is Required" })}
                    />
                    <Validation errors={errors.cardCode} message={errors?.cardCode?.message} watch={watch("cardCode")} />
                  </div>
                </div>
              </div>
              <div class="col-md-6 mb-4">
                <div class="form-group">
                  <label>
                    Customer Type <span class="validation_compulsory">*</span>
                  </label>
                  <div class="fullWidth position-relative">
                    <select
                      className={`form-control text_ellipsis studentSelect ${!errors.customerType?.message
                        ? watch("customerType")
                          ? "valid"
                          : ""
                        : errors.customerType?.message
                          ? "invalid"
                          : ""
                        }`}
                      name="customerType"
                      ref={register({ required: "Customer Type is Required" })}
                    >
                      <option value="">Select Customer Type</option>
                      <option value="BUSINESS">Business</option>
                      <option value="INDIVIDUAL">Individual</option>
                    </select>
                    <Validation errors={errors.customerType} message={errors?.customerType?.message} watch={watch("customerType")} />
                  </div>
                </div>
              </div>
            </div>
            <div class="fullWidth mt-5">
              <input type="submit" className="btn_primary float-left mr-5" value='submit' />
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}

export default SignUp;
